import * as React from 'react';
import Logo from '../components/logo'
import Nav from '../components/navbar'
import "./mystyles.scss"
// import { StaticImage } from 'gatsby-plugin-image'


const AboutPage = () => {
  return (
    <Nav></Nav>
  )
}

export default AboutPage;